import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Heading4 from '../../components/Type/Heading4'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({ location }) =>
(
  <Layout>

    <SEO title="Mercedes-Benz GLC 250 & 250d Buyers Guide"
      description="Buying a Mercedes-Benz GLC 250? Read our buyers guide for prices, specs and advice on how to buy a good one"
      location={location}
    />

    <PageHeader
      title="Mercedes-Benz GLC 250 & 250d Buyers Guide"
      text="Although Mercedes-Benz was late to the premium mid-size SUV sector by the time it launched the GLC-class in 2015, Australia soon went crazy for both the four-wheel drive wagon and coupe variants: in 2018 the GLC became (just) the German maker’s best-selling model down under, and the chic-looking SUV easily eclipsed all its premium rivals."
    />

    <SectionPageMargin>

      <Heading2>Buying a Mercedes-Benz GLC 250? Read our buyers guide for prices, specs and advice on how to buy a good one</Heading2>

      <Heading4>Market Analysis</Heading4>

      <Paragraph1>
        That degree of popularity, combined with market-wide new car supply issues (caused by Covid-related shipping delays and a shortage of automotive computer chips hampering car manufacturing), has seen secondhand values of the GLC-class not just stabilise, but increase. Bargain hunters are likely to be very disappointed.
      </Paragraph1>

      <Paragraph1>
        The models we’re looking at here are the GLC 250 and GLC 250d, in both wagon (codenamed X253) and five-door coupe (C253) guises. The GLC 250 is powered by a 155kW 2.0-litre turbocharged petrol engine, the GLC 250d by a 2.1-litre twin-turbo diesel producing 150kW and a whopping 500Nm of torque, good for towing. All versions have a 9-speed automatic gearbox and all features 4Matic four-wheel drive. The 250 and 250d were discontinued at the time of the GLC-class’s facelift in late 2019, replaced by updated engines.
      </Paragraph1>

      <Paragraph1>
        Although you might think that SUVs are all about practicality, used car values for the sloping-back coupe considerably outstrip those for the luggage-friendly wagon. Based on Summon’s data, collected from the past 12 months of private and dealer sale ads in Australia, the average price for a 2019 GLC 250 (petrol) coupe with 10-20,000km on the clock is around $91,990, whereas you can pick up a similar wagon for up to $15,000 less. The price gap doesn’t yawn quite so wide with older cars, but it you could still pay up to $10,000 less for a 2017 GLC 250 wagon than an equivalent coupe.
      </Paragraph1>

      <Paragraph1>
        In some parts of the world diesels remain popular, but the Australian market favours petrol engines in its GLCs. Looking at average secondhand prices as far back as 2017, almost regardless of mileage diesel wagons are $3,000+ cheaper than their petrol-engined counterparts, while diesel coupes can be up to $4,500 less expensive.
      </Paragraph1>

      <Paragraph1>
        Again, because of their desirability, the normal pattern of depreciation according to mileage of GLC 250s is out the window. Summon data shows that a 2019 coupe with 10,000km on the odometer is yours for $88,140, and yet a 50,000km example is still $85,396. It’s a similar story for the wagon – a 2019 example with 10K on the clock is around
      </Paragraph1>

      <Paragraph1>
        $75,294, but even if you ramp up the mileage to the other side of 40K, the price only falls back to $72,996. Curiously, a 20-30K GLC 250 wagon averages out more expensive than the 10K cars – $76,784.
      </Paragraph1>

      <Paragraph1>
        If your budget runs a little short of these figures then vehicle age can be your friend, at least when looking at the GLC 250 wagon. A 40,000km example of 2019 vintage is $72,996, but if you can find one from 2017 with similar mileage, you could only pay $60,965.
      </Paragraph1>


      <Heading4>What is it?</Heading4>

      <Paragraph1>
        The Mercedes-Benz GLC 250 is a stylish premium mid-sized SUV wearing one of the most coveted badges in the business, and it’s much in demand. This first generation GLC-class was launched in Australia in 2015 and received a mid-life facelift late in 2019, at which stage the 250 petrol and diesel 250d were superseded, replaced by updated engines. The prestigious SUV is available in wagon and five-door coupe body styles.
      </Paragraph1>

      <Paragraph1>
        Although not an off-roader per se, the GLC 250 does feature Mercedes’ 4Matic four-wheel drive system and when new could be ordered with an Off-Road Engineering Package to enhance its outback abilities – it was a $3,500 option and few owners ticked that box. All GLC 250s drive through a 9-speed sports automatic gearbox.
      </Paragraph1>

      <Paragraph1>
        As is the Mercedes way, the GLC 250 could be ordered from new with a wide range of optional packages. Look out especially for cars with the excellent air suspension. Also highly desirable are cars with the AMG Line package – externally they feature AMG front and rear bumpers, AMG 20-inch alloys and rear privacy glass, while inside there’s a multifunction sports steering wheel and a panoramic glass sunroof. AMG Line cars benefit, too, from sportier suspension that sharpens up the handling and improves the ride quality.
      </Paragraph1>

      <Paragraph1>
        Comfortable, spacious, refined and good-looking, the GLC 250’s popularity isn’t hard to fathom.
      </Paragraph1>



      <Heading4>What to look out for</Heading4>
      <Paragraph1>
        <strong>Engine:</strong> To date there don’t seem to have been any endemic issues with either the petrol or turbodiesel engines of the GLC 250. However, do make sure that any recall work has been carried out, particularly the software governing exhaust emissions.
      </Paragraph1>

      <Paragraph1>
        <strong>Electrics:</strong> There have been recalls for the airbags, seat belt pre-tensioners, stability control systems and overheating starter motors. Check if any of these apply to the car you’re looking at and ensure rectification work has been undertaken.
      </Paragraph1>

      <Paragraph1>
        <strong>Suspension:</strong> Some owners report issues with the front tyres ‘crabbing’ or juddering badly on full lock, which mean replacing the tyres after as few as 8,000km. It’s a front suspension problem that can be solved by replacing the steering knuckles – some dealers have replaced them under warranty even though Mercedes claims it isn’t an issue.
      </Paragraph1>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage
